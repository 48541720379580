
import Vue from "vue";
import SelectorFecha from "@/components/selectores/fechas.vue";
import SelectorCajeros from "@/components/selectores/cajeros.vue";
import DialogReportes from "@/components/dialogReportes.vue";
import SnackModule from "@/store/modules/errorSnackbar";
import SpinnerModule from "@/store/modules/spinnerModule";
import selectoresModule from "@/store/modules/selectoresModule";
import AppInput from "@/components/appInput.vue";
import Boton from "@/components/botones/boton.vue";
import { Operacion } from "apd.models";
import { ApiSistemaPagos, ApiErrorResponse, AxiosHttpClient, getUrl, ApiList } from "apd.apiconnectors";
import { mdiFileDocument } from "@mdi/js";
import { DataTableHeader } from "vuetify";

// import JsonExcel from "vue-json-excel";
// import { TableHeader } from "@/models/vuetifyModels";
// import reporte from "@/components/reporte.vue";
// import cajerosForm from "@/components/forms/cajerosForm.vue";
// import cambiosNoDispensadosTable from "@/components/tables/cambiosNoDispensadosTable.vue";

//Vue.component("DownloadExcel", JsonExcel);

export default Vue.extend({
  name: "ReporteCND",
  components: {
    SelectorCajeros,
    SelectorFecha,
    DialogReportes,
    AppInput,
    Boton,
  },
  data: () => ({
    folioApd: "" as string,
    icons: { folio: mdiFileDocument } as Record<string, string>,
    fechaInicial: "" as string,
    fechaFinal: "" as string,
    cajeros: [] as string[] | null,
    data: null as Operacion[] | null,
    api: null as ApiSistemaPagos | null,
    showCnd: false as boolean,
    headers: [
      {
        text: "Fecha",
        value: "created",
        align: "center",
        sortable: true,
      },
      {
        text: "Folio APD",
        value: "folioAPD",
        align: "center",
        sortable: true,
      },
      {
        text: "Cajero",
        value: "nombreCajeroOperacion",
        align: "center",
        sortable: true,
      },
      {
        text: "Importe",
        value: "importe",
        align: "center",
        sortable: false,
      },
      {
        text: "Fecha",
        value: "fechaDND",
        align: "center",
        sortable: false,
      },
      {
        text: "Cajero",
        value: "nombreCajeroDND",
        align: "center",
        sortable: false,
      },
      {
        text: "DND",
        value: "dND",
        align: "center",
        sortable: false,
      },
      {
        text: "Saldo",
        value: "saldo",
        align: "center",
        sortable: false,
      },
    ] as DataTableHeader[],
  }),
  computed: {
    isDark(): boolean {
      return this.$vuetify.theme.dark;
    },
    titulo(): string {
      return "Reporte periodo: " + this.formatDate(this.fechaInicial) + " - " + this.formatDate(this.fechaFinal);
    },
    getNombreCajero() {
      return (id: string): string => {
        const cajero = selectoresModule.cajeros.find((c) => c.id === id);

        if (cajero) {
          return cajero.nombreReportes;
        }
        return "";
      };
    },
  },
  created() {
    const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
    client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    this.api = new ApiSistemaPagos(client);
  },
  methods: {
    formatDate(date: string): string {
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    changeCajeros(data: string[] | null) {
      this.cajeros = data;
      this.folioApd = "";
    },
    async Visualizar(dialog: boolean, show: (value: boolean) => void) {
      SpinnerModule.Show();

      try {
        if (this.api) {
          this.data = (await this.api.OperacionesCNDFiltro({
            kioskos: this.cajeros as string[],
            fechaInicial: this.fechaInicial + "T00:00:00",
            fechaFinal: this.fechaFinal + "T23:59:59",
            folioAPD: this.folioApd == "" ? undefined : this.folioApd,
          })) as Operacion[];
        }
      } catch (e) {
        const err: ApiErrorResponse = e as ApiErrorResponse;
        this.data = [];
        SnackModule.Show(err.apiError.mensajeUsuario);
      } finally {
        SpinnerModule.Hide();
      }

      if (this.data && this.data.length > 0) {
        show(!dialog);
      }
    },
    Exportar() {
      SnackModule.Show("Opcion no disponible por el momento.");
    },
  },
});
