
import Vue from "vue";

export default Vue.extend({
  name: "Boton",
  props: {
    text: {
      type: String,
      required: false,
      default: "",
    },
    color: {
      type: String,
      required: false,
      default: "success",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    click(e: MouseEvent) {
      this.$emit("click", e);
    },
  },
});
