
import Vue from "vue";
import { mdiClose } from "@mdi/js";

export default Vue.extend({
  name: "DialogReportes",
  props: {
    fullscreen: {
      type: Boolean,
      required: false,
      default: true,
    },
    titulo: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      dialog: false,
      icons: { close: mdiClose } as Record<string, string>,
    };
  },
  methods: {
    show(data: boolean) {
      this.dialog = data;
    },
  },
});
