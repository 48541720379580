
import Vue from "vue";
import { mdiCalendar } from "@mdi/js";

export default Vue.extend({
  name: "SelectorFecha",
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    menu: false as boolean,
    tzOffset: 0 as number,
    fecha: "0001-01-01" as string,
    icons: { calendar: mdiCalendar },
  }),
  computed: {
    fechaFormateada(): string {
      return this.formatDate(this.fecha);
    },
  },
  created() {
    this.tzOffset = new Date().getTimezoneOffset() * 60 * 1000;
    this.fecha = new Date(Date.now() - this.tzOffset).toISOString().substr(0, 10);
    this.$emit("change", this.fecha);
  },
  methods: {
    // Metodo para formatear fecha para mostrar
    formatDate(date: string): string {
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    //Evento Input del Date Picker
    onInput() {
      this.menu = false;
      this.$emit("change", this.fecha);
    },
  },
});
