
import Vue from "vue";
import { facade } from "vue-input-facade";

export default Vue.extend({
  name: "AppInput",
  directives: {
    facade,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    mask: {
      type: String,
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    prependIcon: {
      type: String,
      required: false,
      default: "",
    },
  },
});
